<template>
  <input type="file" @change="filesChanged($event.target.name, $event.target.files);">
</template>

<script>
  import ComponentEventNames from '@/enums/component-event-names';

  // This component is an upload field that works on a v-model consisting of two
  // properties: filename and file data in a blob.
  export default {
    name: 'file-upload-field',
    props: ['modelValue'],
    emits: [ComponentEventNames.updateModelValue],
    data() {
      return {
        fileBlob: null,
        fileName: '',
      };
    },
    methods: {
      async filesChanged(name, files) {
        const file = files[0];
        console.log(file);

        const base64String = await this.toBase64(file);
        const fileName = file.name;
        console.log(base64String);
        this.$emit(ComponentEventNames.updateModelValue, {
          data: base64String,
          fileName,
        });
      },

      async toBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      },
    },
  };
</script>

<style scoped>

</style>
