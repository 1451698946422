<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <div class="dataEditorInputField input_list">
    <dt>
      {{ label }}
      <a v-if="schema.guideText" ref="guideText" class="tooltip">
        <span class="fa fa-question-circle"/>
      </a>
    </dt>
    <dd>
      <p v-if="errorMessage !== ''" class="errorMessage">{{errorMessage}}</p>
      <input v-if="editorType==='text'" @focus="clearError"
             :name="schema.fieldName"
             type='text' :placeholder="placeHolderText"
             v-model="editedValue" :disabled="!isEditable"
             :maxlength="maxLength" :data-mandatory="isMandatory" autocomplete='nope'
             :class="{ 'err_msg': !!errMsg }">

      <input v-if="editorType==='email'" @focus="clearError"
             :name="schema.fieldName"
             type="text" :placeholder="placeHolderText"
             v-model="editedValue" :disabled="!isEditable"
             :maxlength="maxLength" :data-mandatory="isMandatory" autocomplete='nope'
             :class="{ 'err_msg': !!errMsg }">

      <input v-if="editorType==='password'" @focus="clearError"
             :name="schema.fieldName"
             type="text" :placeholder="placeHolderText"
             v-model="editedValue" :disabled="!isEditable"
             :maxlength="maxLength" :data-mandatory="isMandatory" autocomplete="new-password"
             :class="{ 'err_msg': !!errMsg }">

      <textarea v-if="editorType==='textarea'"
                :name="schema.fieldName"
                v-model="editedValue" :disabled="!isEditable"
                :maxlength="maxLength" :data-mandatory="isMandatory" autocomplete='nope'
                :class="{ 'err_msg': !!errMsg }"/>

      <input v-if="editorType==='number'" @keypress="validateNumber"
             :name="schema.fieldName"
             type="text" :placeholder="placeHolderText"
             v-model="editedValue" :disabled="!isEditable"
             :maxlength="maxLength" :data-mandatory="isMandatory" autocomplete='nope'
             :class="{ 'err_msg': !!errMsg }">

      <input v-if="editorType==='decimal'"
             :name="schema.fieldName"
             type="text" :placeholder="placeHolderText" @keypress="validateDecimal"
             :value="modelValue" :disabled="!isEditable"
             :maxlength="maxLength" :data-mandatory="isMandatory" autocomplete='nope'
             :class="{ 'err_msg': !!errMsg }">

      <input v-if="editorType==='datepicker'" readonly :name="schema.fieldName"
             type='text' placeholder="DD-MM-YYYY" ref="datepickerref"
             v-model="editedValue" :disabled="!isEditable"
             data-headerformat='MMM YYYY' data-dateformat='DD-MM-YYYY' :data-mandatory="isMandatory"
             :class="{ 'err_msg': !!errMsg }">

      <select v-if="editorType==='dropdown'" @focus="clearError"
              :name="schema.fieldName"
              :disabled="!isEditable"
              :data-mandatory="isMandatory" v-model="editedValue" :class="{ 'err_msg': !!errMsg }">
        <option v-for="option in dropdownList" :value="option.value" :key="option.value">
          {{ option.displayText }}
        </option>
      </select>

      <file-upload-field v-if="editorType==='file'"
                         :name="schema.fieldName"
                         :disabled="!isEditable"
                         v-model="editedValue"
      />

      <input v-if="editorType==='checkbox'"
             :name="schema.fieldName"
             type="checkbox"
             v-model="editedValue" :disabled="!isEditable"
             :data-mandatory="isMandatory" autocomplete='nope'>

      <span v-if="editorType==='checkbox'" :class="{ 'err_msg': !!errMsg }" :name="schema.fieldName">
        {{ schema.translationsName !== null
            ? x(schema.translationsName)
            : schema.fieldName }}
      </span>

    </dd>
  </div>
</template>

<script>
  import moment from 'moment';
  import fillTemplate from 'es6-dynamic-template';
  import tippy from 'tippy.js';
  import { validateEmailOrBlank } from '@/classes/email-helper';
  import 'tippy.js/dist/tippy.css';
  import 'tippy.js/themes/material.css';
  import ComponentEventNames from '@/enums/component-event-names';
  import { httpGet } from '@/classes/httpHelper';
  import FileUploadField from '@/components/Announcements/Editor/FileUploadField';
  import translate from '../../Mixins/Translate';

  export default {
    name: 'data-editor-input-field',
    components: { FileUploadField },
    props: ['modelValue', 'schema', 'isDisabled', 'isAddMode', 'errMsg', 'shouldValidate'],
    emits: [
      ComponentEventNames.updateModelValue,
      'validate',
    ],
    mixins: [translate],
    data() {
      return {
        isEditable: this.schema.userEdit && !this.isDisabled,
        isMandatory: !this.schema.newAllowBlank,
        editedValue: this.formatInput(this.modelValue),
        errorMessage: this.errMsg || '',
        dropdownList: [],
      };
    },
    computed: {
      editorType() {
        const e = (this.schema.editorType === '' || this.schema.editorType == null)
          ? 'text'
          : this.schema.editorType;
        console.log(e);
        return e;
      },
      placeHolderText() {
        if (this.schema.translationsName !== null) {
          return this.x(this.schema.translationsName);
        }
        return false;
      },
      maxLength() {
        return this.schema.maxLength || false;
      },
      label() {
        return this.schema.translationsName !== null
          ? this.x(this.schema.translationsName)
          : this.schema.fieldName;
      },
    },
    watch: {
      errMsg(newValue) {
        this.errorMessage = newValue;
      },
      // The transfer from value to editedValue -
      // we only emit the editedValue through the input event, not updating the prop.
      modelValue(newValue) {
        if (newValue === undefined) {
          if (this.isAddMode && this.schema.newDefault !== null) {
            this.generateNewValue();
          }
        } else {
          this.editedValue = this.formatInput(newValue);
        }
      },
      editedValue(newValue) {
        this.$emit(ComponentEventNames.updateModelValue, newValue);
      },
      async shouldValidate(newValue) {
        if (this.schema.editorType === 'datepicker') {
          const val = this.$refs.datepickerref.value;
          this.editedValue = val;
          this.$emit(ComponentEventNames.updateModelValue, val);
        }
        if (newValue === true) {
          console.log('Validating');
          this.validate();
          this.$emit('validate', this.errorMessage);
        }
      },
    },
    async mounted() {
      await this.setup();

      if (this.schema.guideText) {
        this.setupGuideText();
      }
    },
    methods: {
      setupGuideText() {
        const el = this.$refs.guideText;
        tippy(el, {
          content: this.x(this.schema.guideText),
          arrow: true,
          theme: 'material',
          appendTo: document.body,
        });
      },
      formatInput(inputString) {
        if (inputString == null || inputString === '') return '';
        if (this.schema.editorType === 'datepicker') {
          if (inputString.length === 10) return inputString; // already formatted
          const date = moment.utc(inputString);
          const formattedDate = date.format('DD-MM-YYYY');
          console.log('formatted date: ', formattedDate);
          return formattedDate;
        }
        return inputString;
      },
      validate() {
        if (this.errorMessage !== '') {
          return;
        }

        // noinspection JSRedundantSwitchStatement
        switch (this.schema.editorType) {
          case 'text':
            if (this.isMandatory && this.editedValue.trim() === '') {
              this.errorMessage = this.x('cannotBeBlank');
            }
            break;

          case 'email':
            if (!validateEmailOrBlank(this.editedValue.trim())) {
              this.errorMessage = this.x('invalidEmailFormat');
            }
            break;

          default:
            this.errorMessage = '';
            break;
        }
      },
      clearError() {
        this.errorMessage = '';
      },
      /** Keypress event handler that only allows digits to be entered. */
      validateNumber(event) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          event.preventDefault();
          return false;
        }
        return true;
      },
      validateDecimal(event) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && charCode !== 46 && (charCode < 48 || charCode > 57)) {
          event.preventDefault();
        } else if ((event.target.value.trim().length === 0
          || event.target.value.indexOf('.') !== -1) && charCode === 46) {
          // Prevent dots at the first position and if any currently exist
          event.preventDefault();
        }
      },

      async setup() {
        if (this.editorType === 'datepicker') {
          if (this.modelValue) {
            this.editedValue = moment(this.modelValue.substr(0, 10), 'YYYY-MM-DD').format('DD-MM-YYYY');
          }
          const ref = $(this.$refs.datepickerref);
          ref.datePicker();
        }

        if (this.editorType === 'dropdown') {
          await this.populate_dropdown_data();
        }
      },

      async populate_dropdown_data() {
        let { listSource } = this.schema;
        if (!listSource) return;

        if (listSource.startsWith('Function: ')) {
          listSource = listSource.replace('Function: ', '').toLowerCase();

          const list = await httpGet(listSource);
          if (!list) return;
          list.sort((a, b) => a.sortIndex - b.sortIndex);

          // Changes the template from 'bla bla {{name}} bla' to 'bla bla ${name} bla'
          const template = this.schema.ListDisplayProperty
            .replace(/\{\{/g, '${')
            .replace(/\}\}/g, '}');

          for (const item of list) {
            this.dropdownList.push({
              value: item[this.schema.ListValueProperty],
              displayText: fillTemplate(template, item),
            });
          }
        } else {
          const list = await httpGet(`choices/${listSource}`);
          if (!list) return;
          list.sort((a, b) => a.sortIndex - b.sortIndex);

          for (const item of list) {
            this.dropdownList.push({
              value: item.choice,
              displayText: item.niceName,
            });
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .errorMessage {
    color: red;
  }
</style>
