<template>
  <dl class="subGrid">
    <dt class="topbar">
      <span class="title">{{ title }}</span>
      <a @click="$emit('closeClicked')" class="hand">
        <icon name="fa-close"/>
      </a>
    </dt>
    <dd id="popUpArea"/>
  </dl>
</template>

<script>
  export default {
    name: 'editor-topbar',
    props: ['title'],
    emits: ['closeClicked'],
  };
</script>

<style scoped>
.hand {
  cursor: pointer;
}
</style>
