const emailSeparator = ';';

export function validateEmail(strEmail) {
  // if (!strEmail) return true;

  const validChars = '@_-.0123456789abcdefghijklmnopqrstuvwxyz';
  const minLength = 6;

  const email = strEmail.toLowerCase();
  const allValid = email
    .split('')
    .reduce((total, currentValue) => total && (validChars.indexOf(currentValue) >= 0), true);
  if (!allValid) return false;

  if (email[0] === '@') return false; // cannot start with @
  if (email[0] === '.') return false; // cannot start with .
  if (email[email.length - 1] === '.') return false; // cannot end with .
  if (email[email.length - 1] === '@') return false; // cannot end with @
  if (email.length < minLength) return false;
  if (email.indexOf('..') > -1) return false; // no adjacent ..
  if (email.indexOf('@.') > -1) return false; // no adjacent @.
  if (email.indexOf('.@') > -1) return false; // no adjacent .@
  if (email.split('@').length !== 2) return false; // requires a single @

  return true;
}

/**
 * Validates an email address, accepting empty string as valid.
 * @param {string} strEmail
 * @returns {boolean}
 */
export function validateEmailOrBlank(strEmail) {
  if (strEmail === '' || strEmail == null) return true;
  return validateEmail(strEmail);
}

/**
 * Validates a string with one or more email addresses,
 * possibly separated by whitespace, comma or semicolon.
 * Does not alter the string, but ensures that all addresses
 * are correctly formatted.
 * An empty string is accepted as valid.
 * @param strEmails
 * @returns {boolean} true if all email addresses are valid, false otherwise.
 */
export function validateEmails(strEmails) {
  if (strEmails === '') return true;
  const emails = strEmails.split(/[,;\s]+/);
  const emailCount = emails.length;

  const validEmails = emails
    .map((recipient) => recipient.trim().toLowerCase())
    .filter(validateEmail);
  return emailCount === validEmails.length;
}

/**
 * Validates a string with one or more email addresses
 * and sets semicolon as separator between all addresses
 * Also trims and lowercases them.
 * @param {string} recipientString
 * @returns {string}
 */
export function cleanEmailRecipients(recipientString) {
  // splitting on , and ; and whitespace
  return recipientString.split(/[,;\s]+/)
    .map((recipient) => recipient.trim().toLowerCase())
    .filter(validateEmail)
    .join(emailSeparator);
}

/**
 * Validates a string with one or more email addresses
 * and sets semicolon as separator between all addresses
 * Also trims and lowercases them.
 * Returns them as an array.
 * @param {string} recipientString
 * @returns {string[]}
 */
export function cleanAndSplitEmailRecipients(recipientString) {
  // splitting on , and ; and whitespace
  return recipientString.split(/[,;\s]+/)
    .map((recipient) => recipient.trim().toLowerCase())
    .filter(validateEmail);
}

/**
 * Validates an array of email addresses
 * and returns an array of the valid ones.
 * @param {string[]} emailArray
 * @returns {string[]}
 */
export function cleanEmailArray(emailArray) {
  return emailArray
    .map((recipient) => recipient.trim().toLowerCase())
    .filter(validateEmail);
}

/**
 * Returns the provided array of email addresses join by the standard separator.
 * @param {string[]} emailArray
 * @returns {string}
 */
export function makeString(emailArray) {
  return emailArray.join(emailSeparator);
}
